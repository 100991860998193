<template>
  <div class="collection-layout withTopBar">
    <van-nav-bar title="收款记录" left-text="返回" left-arrow border safe-area-inset-top fixed @click-left="onClickLeft"></van-nav-bar>
    <div class="scroll-box" ref="scroll">
      <van-empty v-show="!loading && collectionData.length==0" description="暂无数据" />
      <div class="item" v-for="(item,index) in collectionData" :key="'collection'+index">
        <div class="flex_box align_center">
          <div class="wd80">订单编号</div>
          <div class="color_gray flex_one pdl10">{{item.order_code}}</div>
        </div>
        <div class="flex_box align_center">
          <div class="wd80">手机</div>
          <div class="color_gray flex_one pdl10">{{item.mobile}}</div>
        </div>
        <div class="flex_box align_center">
          <div class="wd80">地址</div>
          <div class="color_gray flex_one pdl10">{{item.address}}</div>
        </div>
        <div class="flex_box align_center">
          <div class="wd80">金额</div>
          <div class="color_gray flex_one pdl10">{{item.money}}</div>
        </div>
        <div class="flex_box align_center">
          <div class="wd80">收款方式</div>
          <div class="color_gray flex_one pdl10">{{item.op_type_name}}</div>
        </div>
        <div class="flex_box align_center">
          <div class="wd80">收款时间</div>
          <div class="color_gray flex_one pdl10">{{item.op_time}}</div>
        </div>
        <div class="blank"></div>
      </div>
      <div class="loading" v-show="loading">
        <van-loading type="spinner" color="#1989fa" />
      </div>
      <div class="count-box" v-show="collectionData.length>0">合计收款：{{total_money}}元</div>
    </div>
    
  </div>
</template>

<script>
export default {
  name:"collection",
  data(){
    return{
      loading:false,
      total_money:0,
      collectionData:[],
      isMore:true,
      page:1,
      rowCount:10,
    }
  },
  created(){
    this.index()
  },
  mounted(){
    this.$refs.scroll.addEventListener('scroll',this.handleScroll,true);
  },
  methods:{
    index(){
        const _this = this
        _this.loading = true
        _this.$api.common.getReceiveMoneyList({
            page:_this.page,
            rowCount:_this.rowCount
        }).then(res=>{
            _this.loading = false
            if(res.code =='0' && res.data){
                let result = _this.collectionData.concat(res.data.rows)
                _this.total_money = res.data.total_money
                _this.collectionData = result
                _this.page++
                if(res.data.rows.length < _this.rowCount){
                    _this.isMore = false
                }
            }
        }).catch(() => {
            _this.loading = false;
        })
    },
    handleScroll(){
      const _this = this

      const scrollTop = this.$refs.scroll.scrollTop
      // 获取可视区的高度
      const windowHeight = this.$refs.scroll.clientHeight
      // 获取滚动条的总高度
      const scrollHeight = this.$refs.scroll.scrollHeight
      if (scrollTop + windowHeight >= scrollHeight ) {
        // 把距离顶部的距离加上可视区域的高度 等于或者大于滚动条的总高度就是到达底部
        if(_this.isMore && !_this.loading){
          _this.index()
        }
      }
    },
    onClickLeft(){
      this.$router.push({
        name: "home"
      })
    }
  },
  computed:{
    contMoney(){
      let count = 0
      for(let item of this.collectionData){
        count += item.money*1
      }
      return count
    }
  }
}
</script>

<style lang="scss">
@import "./collection-record.scss";
</style>